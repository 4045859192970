<template>
    <div>
        <breadcrumbs />
        <router-view />
    </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs';

export default {
    name: 'Documentation',
    components: {
        Breadcrumbs,
    },
    beforeMount() {
        this.$store.state.useContainer = true;
    },
};
</script>

<style lang="less">
.documentation {
    &__subtitle {
        font-family: @title-font;
        font-weight: 600;
        margin-bottom: 20px;
    }

    &__header {
        display: grid;
        grid-template-columns: 1fr;
        align-items: flex-start;
    }

    &__text {
        margin-bottom: 20px;
    }

    &__link {
        color: @primary;
        transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:hover {
            color: darken(@primary, 15%);
        }
    }

    &__list {
        margin-bottom: 20px;
    }

    &__item {
        position: relative;
        margin-bottom: 10px;
        padding-left: 32px;

        &::before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            left: 8px;
            top: 10px;
            border-radius: 50%;
            background: darken(@secondary, 20%);
        }
    }

    &__code {
        overflow-x: scroll;
        margin-bottom: 20px;

        & div {
            width: 100%;
            min-width: 500px;
            padding: 1.5rem;
            box-sizing: border-box;
        }

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
            background-color: @secondary;
        }

        &::-webkit-scrollbar-track {
            background-color: @secondary;
        }

        &::-webkit-scrollbar-thumb {
            background-color: darken(@secondary, 15%);
        }

        & .ssh-pre__copy {
            position: absolute;
            top: 1.5rem;
            right: 1.5rem;
            border: unset;
            background: unset;
            cursor: pointer;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2em;
    }

    &__cards {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 2em;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__card-name {
        margin-bottom: 10px;
        font-family: @title-font;
        font-weight: 600;
        font-size: 1.1em;
        color: @text;
        text-decoration: none;
        transition: color 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:hover {
            color: @primary;
        }
    }
}

@media (min-width: 480px) {
    .documentation__grid {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 768px) {
    .documentation {
        &__code {
            overflow-x: unset;
        }

        &__header {
            grid-template-columns: 1fr auto;
            grid-gap: 2rem;
        }

        &__cards {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@media (min-width: 992px) {
    .documentation__grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
</style>