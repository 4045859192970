<template>
    <div class="breadcrumb">
        <ul class="breadcrumb__list">
            <icon-file />
            <li
                class="breadcrumb__item"
                v-for="(breadcrumb, idx) in breadcrumbList"
                :key="idx"
                @click="routeTo(idx)"
                :class="{ 'is-linked': !!breadcrumb.link }"
            >
                {{ breadcrumb.name }}
            </li>
        </ul>
    </div>
</template>

<script>
import IconFile from '@/components/icons/IconFile.vue';

export default {
    name: 'Breadcrumb',
    data() {
        return {
            breadcrumbList: [],
        };
    },
    components: {
        IconFile,
    },
    mounted() {
        this.updateList();
    },
    watch: {
        $route() {
            this.updateList();
        },
    },
    methods: {
        routeTo(pRouteTo) {
            if (this.breadcrumbList[pRouteTo].link)
                this.$router.push(this.breadcrumbList[pRouteTo].link);
        },
        updateList() {
            this.breadcrumbList = this.$route.meta.breadcrumb;
        },
    },
};
</script>

<style lang="less">
.breadcrumb {
    &__list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 1em;
        row-gap: 0.5em;
        margin-bottom: 15px;
        font-size: 0.95em;
    }

    &__item {
        &.is-linked {
            position: relative;
            margin-right: 1.2em;
            cursor: pointer;
            color: @text;
            font-weight: normal;

            &:after {
                position: absolute;
                top: 0;
                right: -1em;
                content: '/';
                display: block;
                font-size: 20px;
                color: lighten(@text, 30%);
            }

            &::before {
                content: '';
                position: absolute;
                right: 0;
                bottom: -3px;
                left: auto;
                box-sizing: border-box;
                width: 0;
                height: 2px;
                transition: 0.25s ease all;
                background-color: @primary;
            }

            &:hover::before {
                width: 100%;
                left: 0;
                right: auto;
                transition-duration: 0.5s;
            }
        }

        &:not(.is-linked) {
            color: lighten(@text, 30%);
        }
    }
}
</style>